<template>
  <div>
    <ProductBOMForm :product="product" :errors="errors" :show-billing-unit-error="true" @on-bom-change="onBomChange" />
    <p v-if="hasLastBomMeasurableError" class="error">{{ $t('tasks.defineProductTask.lastBomMeasurableError') }}</p>
    <div class="button-wrapper mt-4">
      <el-button type="secondary" @click="onCancel">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="onSubmit">{{ $t('commons.save') }}</el-button>
    </div>
  </div>
</template>
<script>
import { ref, computed } from 'vue';

import ProductBOMForm, { BOM_ERRORS } from '../task/ProductBOMForm.vue';

export default {
  components: { ProductBOMForm },
  props: {
    product: { type: Object, default: null },
  },
  emits: ['on-submit', 'on-cancel'],
  setup(_, { emit }) {
    const errors = ref([]);
    const bom = ref();

    const onSubmit = () => {
      const newBomErrors = [];
      if (!bom.value) {
        newBomErrors.push({ error: BOM_ERRORS.NO_BOM });
      } else {
        bom.value.forEach((eachBom, index) => {
          if (isNaN(Number(eachBom.quantity)) || eachBom.quantity === '') {
            newBomErrors.push({ index, error: BOM_ERRORS.MISSING_QUANTITY });
          }
        });

        const hasBillingUnit = bom.value.some(({ billingUnit }) => billingUnit === true);
        if (!hasBillingUnit) {
          newBomErrors.push({ error: BOM_ERRORS.NO_BILLING_UNIT });
        }
      }

      if (newBomErrors.length === 0) {
        let bomToUpdate = null;
        for (let i = bom.value.length - 1; i >= 0; i--) {
          const item = bom.value[i];
          const itemToAppend = {
            quantity: Number(item.quantity),
            ...(item.billingUnit === true ? { billingUnit: item.billingUnit } : {}),
            uom: item.unit,
          };
          if (bomToUpdate === null) {
            bomToUpdate = itemToAppend;
          } else {
            bomToUpdate = { ...itemToAppend, bom: bomToUpdate };
          }
        }
        emit('on-submit', { bom: bomToUpdate });
      } else {
        errors.value = newBomErrors;
      }
    };

    const onCancel = () => {
      emit('on-cancel');
    };

    const hasLastBomMeasurableError = computed(
      () => errors.value.find(({ error }) => error === BOM_ERRORS.LAST_BOM_MEASURABLE) !== undefined
    );

    const onBomChange = (changedBom) => {
      if (errors.value.length > 0) {
        errors.value = [];
      }
      bom.value = changedBom;
    };

    return {
      errors,
      hasLastBomMeasurableError,
      onCancel,
      onSubmit,
      onBomChange,
    };
  },
};
</script>
<style scoped lang="scss">
.error {
  color: #cc2d4b;
}
.button-wrapper {
  display: flex;
  justify-content: flex-end;
}
</style>
