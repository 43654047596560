<template>
  <div>
    <ProductCategoryForm
      :product="product"
      :has-error="hasCategoryError"
      :category-trees="categoryTrees"
      :display-select-in-column="true"
      @on-category-select="onCategorySelect"
    />
    <div class="button-wrapper mt-4">
      <el-button type="secondary" @click="onCancel">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="onSubmit">{{ $t('commons.save') }}</el-button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

import ProductCategoryForm from '../task/ProductCategoryForm.vue';

export default {
  components: {
    ProductCategoryForm,
  },
  props: {
    product: { type: Object, default: null },
    categoryTrees: { type: Array, default: () => [] },
  },
  emits: ['on-submit'],
  setup(_, { emit }) {
    const categoryId = ref();
    const hasCategoryError = ref(false);

    const onCategorySelect = (category) => {
      categoryId.value = category;
      if (hasCategoryError.value) {
        hasCategoryError.value = false;
      }
    };

    const onSubmit = () => {
      if (!categoryId.value) {
        hasCategoryError.value = true;
      } else {
        emit('on-submit', { categoryId: categoryId.value });
      }
    };

    const onCancel = () => {
      emit('on-cancel');
    };

    return {
      hasCategoryError,
      onCancel,
      onSubmit,
      onCategorySelect,
    };
  },
};
</script>

<style scoped>
.button-wrapper {
  display: flex;
  justify-content: flex-end;
}
</style>
