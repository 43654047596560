<template>
  <el-form ref="form" v-loading="loading" :model="model">
    <h3 class="mb-4">{{ $t('product.productManagementModal.mainName') }}</h3>

    <div class="row g-0">
      <div class="col-1 pt-2">
        {{ $t('commons.name') }}
      </div>
      <div class="col">
        <el-form-item prop="name" required>
          <el-input v-model="model.name" />
        </el-form-item>
      </div>
    </div>
    <div class="row g-0">
      <div class="col-1 pt-2">
        {{ $t('commons.sku') }}
      </div>
      <div class="col-5" :class="$direction === 'ltr' ? 'pe-7' : 'ps-7'">
        <el-form-item prop="sku">
          <el-input v-model="model.sku" />
        </el-form-item>
      </div>
      <div class="col-1 pt-2">{{ $t('product.gtin') }}</div>
      <div class="col-5">
        <el-form-item prop="gtin">
          <el-input v-model="model.gtin" />
        </el-form-item>
      </div>
    </div>
    <h3 class="mb-4">{{ $t('product.additionalNames') }}</h3>
    <Table :data="references.slice(1)" :columns="columns" :hover="false" show-index border rounded>
      <template #cell-name="{ rowData: { name } }">
        <p>{{ name }}</p>
      </template>
      <template #cell-sku="{ rowData: { sku } }">
        <p>{{ sku }}</p>
      </template>
    </Table>
    <div class="button-wrapper mt-4">
      <el-button type="secondary" @click="onCancel">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="onSubmit">{{ $t('commons.save') }}</el-button>
    </div>
  </el-form>
</template>

<script>
import { pick, map } from 'ramda';

import { Table } from '@/modules/core/components';
import { ProductSchema } from '@/modules/products/models/product';

const REFERENCES_NAMES_TABLE_HEADERS = {
  NAME: 'name',
  SKU: 'sku',
};

const getEmptyModel = () => ({
  name: '',
  sku: '',
  gtin: '',
});

export default {
  components: { Table },
  props: {
    loading: { type: Boolean, default: false },
    product: { type: Object, default: () => null },
  },
  data() {
    return {
      model: Object.assign(getEmptyModel(), ProductSchema.clean(this.product || {})),
    };
  },
  computed: {
    references() {
      return this.product?.references ?? [];
    },
    columns() {
      return [
        {
          header: this.$t('commons.sku'),
          key: REFERENCES_NAMES_TABLE_HEADERS.SKU,
        },
        {
          header: this.$t('commons.name'),
          key: REFERENCES_NAMES_TABLE_HEADERS.NAME,
        },
      ];
    },
  },
  watch: {
    product(value) {
      this.model = Object.assign(getEmptyModel(), pick(Object.keys(getEmptyModel()), ProductSchema.clean(value || {})));
    },
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (!valid) return;
        this.$emit('submit', { ...map((value) => value || null, this.model), fromProductEdit: true });
      });
    },
    onCancel() {
      this.$emit('on-cancel');
    },
  },
};
</script>

<style scoped>
.button-wrapper {
  display: flex;
  justify-content: flex-end;
}
</style>
