<template>
  <PageLayout>
    <template #header>
      <h1 class="product-title">{{ $t('productsSupplier') }}</h1>
    </template>
    <Tabs class="mb-4 d-flex justify-space-around" :tabs="tabs" :active-tab.sync="activeTab" />
    <ProductsTable
      v-show="activeTab === TABS.PRODUCTS"
      :loading="loading"
      :products="products"
      :suppliers="businesses"
      :is-supplier-view="true"
      :page="page"
      @on-page-change="page = $event"
      @on-product-click="selectedProductId = $event"
      @on-products-remove-from-catalogs="onProductsRemoveFromCatalogs"
    />
    <Catalogs v-if="activeTab === TABS.CATALOGS" :is-supplier-view="true" :table-page-limit="CATALOG_LIMIT" />
    <ProductManagementModal
      v-if="selectedProductId"
      :product-id="selectedProductId"
      :category-trees="categoryTrees"
      :is-supplier-view="true"
      @closed="onCloseProductManagementModal"
    />
  </PageLayout>
</template>

<script>
import { PageLayout } from '@/modules/core/components';
import { computed, getCurrentInstance, ref } from 'vue';
import { Tabs } from '@/modules/core/components';
import { useProductsNew, useCategories } from '../../compositions';
import { useFetchAll } from '@/modules/purchase-management/compositions/useFetchAll';
import { useCatalogs } from '../../../catalog/compositions/useCatalog';
import ProductsTable from '../products/ProductsTable';
import ProductManagementModal from '../productManagementModal/ProductManagementModal';
import Catalogs from '@/modules/catalog/components/Catalogs.vue';
import { useTenancy } from '@/modules/auth';
import { useBusinessById } from '../../compositions/useBusinessById';

const ALL_CATALOGS_LIMIT = 500;
const CATALOG_LIMIT = 15;
const PRODUCTS_FETCH_LIMIT = 500;
const TABS = {
  PRODUCTS: 0,
  CATALOGS: 1,
};

export default {
  components: { Tabs, PageLayout, ProductsTable, ProductManagementModal, Catalogs },
  setup() {
    const root = getCurrentInstance().proxy;
    const { currentTenant } = useTenancy();
    const page = ref(1);
    const selectedProductId = ref();
    const activeTab = ref(0);

    const businessId = computed(() => currentTenant.value.id);

    const {
      allResults: products,
      loading: productsLoading,
      refetch: refetchProducts,
    } = useFetchAll({
      hook: useProductsNew,
      variables: {
        businessId: businessId.value,
      },
      dataSelector: 'productsNew',
      limit: PRODUCTS_FETCH_LIMIT,
    });

    const { business, loading: businessLoading } = useBusinessById(businessId);
    const { categoryTrees, loading: categoriesLoading } = useCategories();
    const {
      allResults: catalogs,
      loading: catalogLoading,
      refetch: refetchCatalogs,
    } = useFetchAll({
      limit: ALL_CATALOGS_LIMIT,
      dataSelector: 'catalogs',
      hook: useCatalogs,
      variables: { businessId },
    });

    const loading = computed(
      () =>
        (productsLoading.value && categoriesLoading.value && catalogLoading.value) ||
        productsLoading.value ||
        businessLoading.value
    );

    const mappedProducts = computed(() => {
      return products.value.map((product) => {
        const relevantCatalogs = catalogs.value.filter(({ productIds }) => productIds.includes(product.id));

        return {
          ...product,
          catalogs: relevantCatalogs,
          availability: !catalogLoading.value ? relevantCatalogs.length > 0 : undefined,
        };
      });
    });

    const onCloseProductManagementModal = () => {
      selectedProductId.value = null;
      refetchCatalogs();
      refetchProducts();
    };

    const onProductsRemoveFromCatalogs = () => {
      refetchCatalogs();
      refetchProducts();
    };

    const tabs = computed(() => [
      {
        text: root.$t('product.productManagement.tab.products'),
      },
      {
        text: root.$t('catalog.title'),
      },
    ]);

    return {
      TABS,
      tabs,
      businesses: [business],
      categoryTrees,
      selectedProductId,
      page,
      products: mappedProducts,
      loading,
      activeTab,
      onCloseProductManagementModal,
      onProductsRemoveFromCatalogs,
      CATALOG_LIMIT,
    };
  },
};
</script>
<style lang="scss">
.product-title {
  color: #1b1e22;
}
</style>
