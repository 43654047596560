<template>
  <div v-loading="isLoading">
    <ProductCatalogForm :product="product" :catalogs="catalogs" @on-catalog-change="onCatalogChange" />
    <div class="d-flex justify-content-end">
      <el-button type="secondary" @click="onCancel">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="onSubmit">{{ $t('commons.save') }}</el-button>
    </div>
  </div>
</template>
<script>
import { computed, getCurrentInstance, ref } from 'vue';

import ProductCatalogForm from '../task/ProductCatalogForm.vue';
import { useCreateProductInCatalog } from '../../../catalog/compositions/useCreateProductInCatalog';
import { useDeleteProductInCatalog } from '../../../catalog/compositions/useDeleteProductInCatalog';
import { debounce } from 'lodash';
import { useFetchAll } from '@/modules/purchase-management/compositions/useFetchAll';
import { useCatalogs } from '@/modules/catalog/compositions/useCatalog';

const CATALOG_LIMIT = 500;

export default {
  name: 'CatalogTab',
  components: { ProductCatalogForm },
  props: { product: { type: Object, default: null } },
  emits: ['on-submit', 'on-cancel'],
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;
    const catalogsToAdd = ref();
    const catalogsToRemove = ref();
    const updateCatalogsIsLoading = ref(false);

    const {
      createProductInCatalog,
      loading: createProductInLoading,
      onDone: onCreateDone,
      onError: onCreateError,
    } = useCreateProductInCatalog();
    const {
      deleteProductInCatalog,
      loading: deleteProductInLoading,
      onDone: onDeleteDone,
      onError: onDeleteError,
    } = useDeleteProductInCatalog();

    const {
      allResults: catalogs,
      loading,
      refetch: refetchCatalogs,
    } = useFetchAll({
      limit: CATALOG_LIMIT,
      dataSelector: 'catalogs',
      hook: useCatalogs,
      variables: { businessId: props.product.business.id },
    });

    const debouncedShowMessage = debounce(() => {
      if (!hasError.value && !createProductInLoading.value && !deleteProductInLoading.value) {
        root.$message.success(root.$t('product.productManagementModal.updatedProduct'));
      }
    }, 500);

    const hasError = ref(false);

    onCreateError(() => {
      hasError.value = true;
    });

    onDeleteError(() => {
      hasError.value = true;
    });

    onCreateDone(() => {
      debouncedShowMessage();
    });

    onDeleteDone(() => {
      debouncedShowMessage();
    });

    const onCancel = () => {
      emit('on-cancel');
    };

    const onSubmit = async () => {
      updateCatalogsIsLoading.value = true;
      try {
        for (const id of catalogsToAdd.value) {
          await createProductInCatalog({ id, params: { productId: props.product.id } });
        }

        for (const id of catalogsToRemove.value) {
          await deleteProductInCatalog({ id, params: { productId: props.product.id } });
        }
      } catch (error) {
        console.log('update catalog products error: ', error);
      }
      emit('on-submit');
      refetchCatalogs();
      updateCatalogsIsLoading.value = false;
    };

    const onCatalogChange = ({ catalogsToAdd: newCatalogsToAdd, catalogsToRemove: newCatalogsToRemove }) => {
      catalogsToAdd.value = newCatalogsToAdd;
      catalogsToRemove.value = newCatalogsToRemove;
    };

    const isLoading = computed(
      () =>
        loading.value || updateCatalogsIsLoading.value || createProductInLoading.value || deleteProductInLoading.value
    );

    return {
      createProductInLoading,
      deleteProductInLoading,
      onCancel,
      onSubmit,
      onCatalogChange,
      catalogs,
      loading,
      isLoading,
    };
  },
};
</script>
<style lang="scss" scoped></style>
