<template>
  <el-dialog
    v-loading.fullscreen="mutationLoading"
    visible
    top="30vh"
    width="50rem"
    custom-class="rounded product-management-modal"
    :show-close="false"
  >
    <template #title>
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex flex-column">
          <template v-if="product">
            <h2>{{ product.name }}</h2>
            <p>{{ product.sku }} · {{ product.business.name }}</p>
          </template>
        </div>

        <Button type="text" class="p-0 text-typography-primary close-btn" @click="close">
          <CloseIcon />
        </Button>
      </div>
    </template>
    <div v-loading="loading">
      <div v-if="!isSupplierView">
        <Tabs class="mb-4" :tabs="tabs" :active-tab.sync="activeTab" />
        <ProductEdit v-show="activeTab === 0" :product="product" @submit="onSubmit" @on-cancel="close" />
        <AssociatedProductsTab v-if="activeTab === 1 && product" :parent-product="product" @refetch="refetch" />
        <UnitsOfMeasure v-if="activeTab === 2" :product="product" @on-submit="onSubmit" @on-cancel="close" />
        <ClarityCategory
          v-show="activeTab === 3"
          :category-trees="categoryTrees"
          :product="product"
          @on-submit="onSubmit"
          @on-cancel="close"
        />
        <CatalogTab v-if="activeTab === 4" :product="product" @on-submit="onSubmit" @on-cancel="close" />
      </div>

      <div v-else :class="!product && 'product-catalogs'">
        <div v-if="product">
          <CatalogTab :product="product" @on-submit="onSubmit" @on-cancel="close" />
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { filter, isNil, not, pipe } from 'ramda';
import { ref, computed, getCurrentInstance } from 'vue';

import { Button, Tabs } from '@/modules/core/components';

import { useProductNew } from '../../compositions/product';
import AssociatedProductsTab from '../associatedProducts/AssociatedProductsTab';

import ProductEdit from './ProductEdit';
import UnitsOfMeasure from './UnitsOfMeasure';
import ClarityCategory from './ClarityCategory';
import CatalogTab from './CatalogTab';
import { useProductUpdateNew } from '../../compositions/productUpdateNew';
import { useProductCreate } from '../../compositions/productCreate';
import { CloseIcon } from '@/assets/icons';

const filterNil = filter(pipe(isNil, not));

export default {
  components: {
    Tabs,
    ProductEdit,
    UnitsOfMeasure,
    ClarityCategory,
    CatalogTab,
    AssociatedProductsTab,
    Button,
    CloseIcon,
  },
  props: {
    productId: { type: String, default: '' },
    categoryTrees: { type: Array, default: () => [] },
    isSupplierView: { type: Boolean, default: false },
  },
  emits: ['closed'],
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;
    const { product, loading, refetch } = useProductNew(computed(() => props.productId));
    const { mutate: create, loading: createLoading, onDone: createOnDone, onError: createOnError } = useProductCreate();
    const {
      mutate: update,
      loading: upadateLoading,
      onDone: updateOnDone,
      onError: updateOnError,
    } = useProductUpdateNew();

    const tabs = computed(() => [
      {
        text: root.$t('product.productManagementModal.names'),
      },
      {
        text: root.$t('product.productManagementModal.associatedProducts'),
      },
      {
        text: root.$t('product.productManagementModal.uom'),
      },
      {
        text: root.$t('product.productManagementModal.clarityCategory'),
      },
      {
        text: root.$t('product.productManagementModal.catalogs'),
      },
    ]);

    createOnDone(() => {
      root.$message.success(root.$t('product.productManagementModal.createdProduct'));
      refetch();
    });
    createOnError(() => root.$message.error(root.$t('product.productManagementModal.failed')));
    updateOnDone(() => {
      root.$message.success(root.$t('product.productManagementModal.updatedProduct'));
      refetch();
    });
    updateOnError(() => root.$message.error(root.$t('product.productManagementModal.failed')));

    const close = () => {
      emit('closed');
    };

    const onSubmit = (data) => {
      if (!data) return;
      if (product.value.id) {
        const payloadToUpdate = {
          id: product.value.id,
          productData: {
            businessId: product.value.business.id,
            name: data.name ?? product.value.name,
            sku: data.fromProductEdit ? data.sku : product.value.sku,
          },
        };

        if (data.bom) {
          payloadToUpdate.productData.bom = data.bom;
        }

        if (data.gtin) {
          payloadToUpdate.productData.gtin = data.gtin;
        }

        if (data.categoryId) {
          payloadToUpdate.productData.categoryId = data.categoryId;
        }

        update(payloadToUpdate);
      } else {
        create({ productData: filterNil(data) });
      }
    };

    return {
      tabs,
      product,
      loading,
      activeTab: ref(0),
      mutationLoading: computed(() => createLoading.value || upadateLoading.value),
      close,
      refetch,
      create,
      update,
      onSubmit,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.product-catalogs {
  height: 12rem;
}

.close-btn {
  height: fit-content;
  &:hover {
    background: $secondary;
  }
}
</style>

<style lang="scss">
.product-management-modal {
  .el-dialog__body {
    padding-bottom: 16px !important;
  }
}
</style>
